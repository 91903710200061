<template>
  <el-dialog custom-class="custom-dialog" title="" :visible.sync="dialogVisible" :close-on-click-modal="false"
    append-to-body width="1200px" top="0" @close="closeDialog">
    <div>
      <div class="tabBut">
        <!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value"
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->

        <el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
        </el-button>
        <el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'"
          @click="detailsItem.seleId=2" :type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
        <el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
      </div>
      <div>
        <alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" :stateList="stateList" />
        <operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
        <process v-else :dioData="dioDetailsItem" :stateListDown="stateList" />
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex;align-items: center;justify-content: center;">
        <!-- <div v-if="detailsItem.seleId==1">
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="primary" @click="sosClick(1)">
						<span>人为触发(无故障)</span>
						<p style="margin: 10px 0 0 0;">确认关闭</p>
					</el-button>
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="info" @click="sosClick(2)">
						<span>电梯故障(无困人)</span>
						<p style="margin: 10px 0 0 0;">派发维修工单</p>
					</el-button>
					<el-button :disabled='dioDetailsItem.sosStatus!=1' size="mini" type="warning" @click="sosClick(3)">
						<span>困人</span>
						<p style="margin: 10px 0 0 0;">立即派发维修工单</p>
					</el-button>
				</div> -->
      </span>
    </div>
    <div class="null"></div>
  </el-dialog>
</template>
<script>
  import alarmMessage from '../../yjgl/yjjy/components/alarmMessage'
  import operational from '../../yjgl/yjjy/components/operational'
  import process from '../../yjgl/yjjy/components/process'
  export default {
    props: {
      detailsItem: Object,
      initDioDetailsItem: Object,
      // stateList:Array
    },
    data() {
      return {
        dialogVisible: true,
        dioDetailsItem: this.initDioDetailsItem,
        stateList: [{
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '已处理'
        }, {
          value: 3,
          label: '进行中'
        }, {
          value: 4,
          label: '已完成'
        }],
      }
    },
    components: {
      alarmMessage,
      operational,
      process
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog')
      },
      // 详情操作按钮
      sosClick(type) {
        if (this.dioDetailsItem.submitType === 0) {
          this.dioDetailsItem.sosStatus = 2
          this.$http
            .post("/api/ele/app/sos/submit", {
              id: this.dioDetailsItem.id,
              submitType: type,
              sosTime: this.dioDetailsItem.sosTime
            })
            .then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
              this.closeDialog()
              // this.onRefer()
              // this.showDetails(this.tableData.find(item => item.id === this.dioDetailsItem.id))
            })
        }
      },
    },
    created() {

    }
  }
</script>

<style lang="scss" scoped>
  .scrollElement {
    margin: 20px 0 0 0;
    height: 55vh;
    overflow-y: scroll;
    padding-right: 20px
  }

  .null {
    height: 90px;
  }

  .tabBut {
    text-align: center;
  }
</style>
