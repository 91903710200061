<template>
    <el-dialog
        custom-class="custom-dialog"
        title="故障申报详情"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement" v-loading="!dataValType">
            <dioDetails
                v-if="dataValType"
                :dioData="dataVal"
                :flowList="flowList"
                ></dioDetails>
        </div>
        <div class="null"></div>
    </el-dialog>    
</template>
<script>
import dioDetails from '../../gzsb/gzsb/components/dioDetails'
export default {
    props:{
        dioDetailsItem:Object,
        flowList:Array,
    },
    data() {
        return {
            dialogVisible:true,
            dataVal: {},
            dataValType: false,
            gzType: [
                {
                value: 1,
                label: '维保发现',
                },
                {
                value: 2,
                label: '救援发现',
                },
                {
                value: 3,
                label: '巡逻发现',
                },
                {
                value: 4,
                label: '乘梯发现',
                },
                {
                value: 5,
                label: '平台发现',
                },
                {
                value: 6,
                label: '监控发现',
                },
            ],
        }
    },
    components: {
        dioDetails,
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog')
        },
        getData(){
                this.$http.get(`/api/ele/web/failureTask/taskDetail?id=${this.dioDetailsItem.id}`).then((res) => {
                    if(res.status==200&&res.data.code==200){
                        this.dataVal=this.dioDetailsItem
                        this.dataVal.statusName = this.getStatusText(this.dataVal.status)
                        this.dataVal.sourceName = this.getTypeText(this.dataVal.sourceType)
                        this.dataValType = true
                    }
                    
                })
        },
        getStatusText(status){
            const list=this.clType.filter(item=>item.dictKey==status)
            return list.length>0?list[0].dictValue:status
        },
        getTypeText(status){
            const list=this.gzType.filter(item=>item.value==status)
            return list.length>0?list[0].label:status
        },
        //获取状态字典
        async getDictionary() {
            const res=await this.$http.get(`/api/system/web/dict/dictionary?code=FAILURE_STATUS`)
            if(res.status==200&&res.data.code==200){
                this.clType=res.data.data
            }
        },
        
    },
    created(){
        this.getDictionary()
        
    },
    mounted(){
        this.$nextTick(()=>{
            // this.getData()
        })
    }
}
</script>

<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    height: 72vh; 
    overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
</style>