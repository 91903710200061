<template>
  <div class="scrollElement showEl">
    <div style="display: flex;margin-bottom: 20px;" v-loading="loading">
      <div style="width: 50%;">
        <el-card>
          <span style="width: 100%;display: flex;justify-content: space-between; align-items: center;">
            电梯名称: {{dioDataitem.eleName}}
            <el-button type="danger" size="mini">{{dioDataitem.sosTypeName}}</el-button>
          </span>
          <p style="margin-top: 8px">电梯注册代码: {{dioDataitem.eleCode}}</p>
          <p>报警时间: {{dioDataitem.sosTime}}</p>
          <div :class="!(adminInfo.roleId == '99') ? 'timeType':'timeTypes'" v-if="dioDataitem.sosCount != 0">
            <p>连续报警次数：{{dioDataitem.sosCount ? dioDataitem.sosCount+'次' : ''}}</p>
            <p style="width: 300px;"> 最后一次报警时间：{{dioDataitem.finallySosTime ? dioDataitem.finallySosTime :''}}</p>
          </div>
          <p>维保单位: {{dioDataitem.maintainUnitName}}</p>
          <p>物业单位: {{dioDataitem.propertyUnitName}}</p>
          <p>所属小区: {{dioDataitem.plotName}}</p>
          <span>地点: {{dioDataitem.eleAddress}}</span>
        </el-card>
        <div style="margin-top: 15px;border-radius: 5px; overflow: hidden;">
          <div id="allmap"></div>
        </div>
      </div>
      <div class="scrollElement" style="height: 55vh;width: 50%;margin: auto; overflow-y: scroll;">
        <el-timeline>
          <el-timeline-item style="width: 100%;text-align: left;" v-for="(activity, index) in rescue" :key="index"
            :timestamp="activity.timestamp" v-if="activity.status != 14 || dioDataitem.rescueTask.isAuto === 0">
            <!-- <h3 style="margin: 0;">
              {{activity.sosStatusName}}
              <span style="font-size: 14px;">
                {{dioData.rescueTask.isAuto == 1 && activity.status==15? '(自动识别完成)' : (dioData.rescueTask.isAuto == 3&& activity.status==15 ? '(管理员操作完成)' : '')}}
              </span>
            </h3> -->
            <h3 style="margin: 0;">
              <div style="display: flex;justify-content: space-between;align-items: flex-start;">
                <div >
                  {{activity.sosStatusName}}
                  <span style="font-size: 14px;">
                    {{dioDataitem.rescueTask.isAuto == 1 && activity.status==15? '(自动识别完成)' : (dioDataitem.rescueTask.isAuto == 3&& activity.status==15 ? '(管理员操作完成)' : '')}}
                  </span>
                </div>
                <el-button type="primary"
                  v-if="activity.status== 13&&adminInfo.userName == 'admin'&&propTime(activity.time)&&(dioDataitem.rescueTask.status == 3 || dioDataitem.rescueTask.status == 1)"
                  size="small" style="margin-right: 10px;margin-top: -5px;" @click="checkTime(activity)">救援完成</el-button>
              </div>
            </h3>
            <p>{{activity.time}}</p>
            <!-- <p v-if="activity.status==1||activity.status==15||activity.status==25">{{activity.time}}</p> -->

            <template v-if="activity.status==2||activity.status==13||activity.status==23">
              <p>账号:&ensp;{{activity.userName}}</p>
              <p>{{activity.status==2?'接警时间':'确认时间'}}:&ensp;{{activity.time}}</p>
            </template>

            <!-- <template v-if="activity.status==14">
              <p>{{activity.users}}</p>
              <p>{{dioDataitem.rescueTask.isAuto === 0 ? '所用时长' : '救援时长'}}:&ensp;{{activity.time}}</p>
              <p>{{dioDataitem.rescueTask.isAuto === 0 ? '救援到场时间' : '救援开始时间'}}:&ensp;{{activity.start}}</p>
              <p>{{dioDataitem.rescueTask.isAuto === 0 ? '救援完成时间' : '救援结束时间'}}:&ensp;{{activity.end}}</p>
              <p>救援描述:&ensp;{{activity.desc}}</p>
            </template> -->

            <template v-if="activity.status==14 && dioDataitem.rescueTask.isAuto === 0||activity.status==24">
              <p>{{activity.users}}</p>
              <p> 所用时长:&ensp;{{activity.time}}</p>
              <p>救援到场时间:&ensp;{{activity.start}}</p>
              <p>救援完成时间:&ensp;{{activity.end}}</p>
              <p>救援描述:&ensp;{{activity.desc}}</p>
            </template>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <el-dialog v-if="timeVisible" :visible.sync='timeVisible' :close-on-click-modal="false" title="救援完成" width="700px"
      append-to-body @close="timeVisible=false">
      <div style="padding: 0px 20px;">
        <el-form :model="forms" ref="forms" label-width="130px" label-position="right" :rules="rules">
          <el-form-item label="电梯名称">
            <el-input v-model="forms.eleName" disabled></el-input>
          </el-form-item>
          <el-form-item label="最后一次报警时间">
            <el-input v-model="forms.finallySosTime" disabled></el-input>
          </el-form-item>
          <el-form-item label="确认救援时间">
            <el-input v-model="forms.sureTime" disabled></el-input>
          </el-form-item>
          <el-form-item label="到场时间" prop="arrivalTime">
            <el-date-picker v-model="forms.arrivalTime" type="datetime" placeholder="请选择到场时间,不早于确认救援时间"
              style="width: 100%;">
            </el-date-picker>
            <!-- <el-input v-model="forms.arrivalTime" placeholder="请输入到场时间,不早于确认救援时间"></el-input> -->
          </el-form-item>
          <el-form-item label="救援结束时间" prop="endTime">
            <el-date-picker v-model="forms.endTime" type="datetime" placeholder="请选择救援结束时间,不早于以上时间"
              style="width: 100%;">
            </el-date-picker>
            <!-- <el-input v-model="forms.endTime" placeholder="请输入救援结束时间,不早于以上时间"></el-input> -->
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="sure()">确定</el-button>
        <el-button @click="close()">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  let map
  let marker
  export default {
    props: ['dioData', 'stateListDown'],
    data() {
      return {
        forms: {},
        loading: false,
        rules: {
          arrivalTime: [{
              required: true,
              message: '请输入到场时间',
              trigger: 'change'
            },
            {
              validator: this.validateTemp,
              trigger: 'change'
            }
          ],
          endTime: [{
              required: true,
              message: '请输入救援结束时间',
              trigger: 'change'
            },
            {
              validator: this.validateTime,
              trigger: 'change'
            }
          ]
        },
        timeVisible: false,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        lineArr: [], //轨迹
        sosWay: [],
        rescue: null,
        stateLists: [{
          value: 1,
          label: '待处理'
        }, {
          value: 2,
          label: '已处理'
        }, {
          value: 3,
          label: '进行中'
        }, {
          value: 4,
          label: '已完成'
        }],
        stateList: [{
          value: 1,
          label: '发生报警'
        }, {
          value: 2,
          label: '人员接警'
        }, {
          value: 3,
          label: '已完成'
        }, {
          value: 23,
          label: '确认故障'
        }, {
          value: 24,
          label: '故障维修中'
        }, {
          value: 25,
          label: '已完成'
        }, {
          value: 13,
          label: '确认救援'
        }, {
          value: 14,
          label: '救援行动'
        }, {
          value: 15,
          label: '已完成'
        }]
      }
    },
    mounted() {
      console.log(this.dioDataitem, 'dioDataitem')
      map = new AMap.Map('allmap', {})
      if (this.dioDataitem.rescueTaskRoute.length > 0) {
        this.dioDataitem.rescueTaskRoute.forEach((qs, ind) => {
          let palArr = JSON.parse(qs.gpsRoute)
          marker = new AMap.Marker({
            map: map,
            position: palArr[0],
            icon: "https://webapi.amap.com/images/car.png",
            offset: new AMap.Pixel(-26, -13),
            autoRotation: true,
            angle: -90,
            title: qs.userName,
          });

          map.setFitView([marker]);

          // 绘制轨迹
          var polyline = new AMap.Polyline({
            map: map,
            path: palArr,
            showDir: true,
            strokeColor: "#28F", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6, //线宽
            // strokeStyle: "solid"  //线样式
          });

          let passedPolyline = new AMap.Polyline({
            map: map,
            // path: lineArr,
            strokeColor: "#AF5", //线颜色
            // strokeOpacity: 1,     //线透明度
            strokeWeight: 6, //线宽
            // strokeStyle: "solid"  //线样式
          });

          marker.on('moving', function(e) {
            passedPolyline.setPath(e.passedPath);
          });

        })
      }
      var marker1 = new AMap.Marker({
        map: map,
        position: [this.dioDataitem.ypoint, this.dioDataitem.xpoint], //位置
      })
      // markers.push(marker)
      map.add(marker1) //添加到地图
      map.setCenter([this.dioDataitem.ypoint, this.dioDataitem.xpoint])

    },
    created() {
      this.dioDataitem = this.dioData ? this.dioData : ''
      this.checkRecuse()
    },
    methods: {
      checkRecuse() {
        this.rescue = this.dioDataitem.disposalProcess.rescue
        if (this.rescue == undefined) {
          this.rescue = this.dioDataitem.disposalProcess.failure
          if (this.rescue == undefined) {
            this.rescue = this.dioDataitem.disposalProcess.sos
          }
        }

        this.sosWay = this.dioDataitem.rescueTaskRoute

        if (this.rescue != undefined) {
          this.rescue.forEach((qs) => {
            qs.sosStatusName = this.getType(qs.status)
          })
        }
      },
      validateTemp(rule, value, callback) {
        console.log(value, new Date(this.forms.sureTime), 'activeTime')
        if (value <= new Date(this.forms.sureTime)) {
          callback(new Error('到场时间不能早于确认救援时间!'))
        }
        callback()
      },
      validateTime(rule, value, callback) {
        console.log(value, this.forms.arrivalTime, 'finshTime')
        if (value <= this.forms.arrivalTime) {
          callback(new Error('救援结束时间不能早于以上时间!'))
        }
        callback()
      },
      sure() {
        const data = {
          id: this.dioDataitem.rescueTask.id,
          presentTime: new Date(this.forms.arrivalTime).getTime(),
          finishTime: new Date(this.forms.endTime).getTime()
        }
        this.$refs.forms.validate((valid) => {
          if (valid) {
            this.$http.post('/api/ele/web/rescueTask/finishRescueTask', data).then((res) => {
              console.log(res, '手动完成救援')
              if (res.data.code == 200) {
                this.loading = true
                this.showDetails(this.dioDataitem.rescueTask)
                this.$emit('changeInfo', 4)
                this.timeVisible = false
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          }
        })
      },
      showDetails(item) {
        console.log(item, 'item66')
        this.$http
          .post("/api/ele/web/rescueTask/getInfo", {
            id: item.id,
            sosTime: item.sosTime,
          })
          .then((res) => {
            if (res.data.success) {
              res.data.data.sosVideo.forEach((qs) => {
                let url = qs.videoUrl.split('?');
                qs.videoUrl = url[0]
                const urlname = url[0].split('/').pop().split('_')
                qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/')
                  .pop() : ''
              })

              res.data.data.sosTypeName = this.dioDataitem.sosTypeName
              res.data.data.sosStatusName = this.stateLists.find(item => item.value === res.data.data
                  .sosStatus) ? this.stateLists.find(item => item.value === res.data.data.sosStatus)
                .label : ''
              res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
              res.data.data.speedarr = res.data.data.speedRecord.split(',');

              // this.detailsItem.seleId = 3
              this.dioDataitem = res.data.data
              this.dioDataitem.emergencyNames = res.data.data.emergencyRescueUserNames.split(';')
              this.dioDataitem.maintainNames = res.data.data.maintainUsers.split(';')
              this.dioDataitem.propertyNames = res.data.data.propertyUsers.split(';')
              this.dioDataitem.status = item.status
              console.log(this.dioDataitem, ' this.dioDataitem')
              this.loading = false
              this.checkRecuse()
              // this.dioDetails = true

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
      propTime(time) {

        // 将救援时间字符串转换为Date对象
        var reueTime = new Date(time).getTime();

        // 获取当前时间
        var currentTime = new Date().getTime();

        // 计算当前时间和救援时间的时间差（毫秒）
        var timeDiff = currentTime - reueTime;

        // 将时间差转换为分钟
        var timeDiffMinutes = timeDiff / 60000;

        // console.log(time, currentTime > reueTime, timeDiffMinutes, '时间')

        // 检查时间差是否大于30分钟
        if (timeDiffMinutes > 30) {
          return true
          console.log('救援时间已超过30分钟。');
          // 此处可以执行后续的操作
        } else {
          console.log('救援时间未超过30分钟。');
          return false
          // 此处可以执行其他操作
        }
      },
      close() {
        this.timeVisible = false
      },
      checkTime(item) {
        console.log(item, this.dioDataitem, 'item')
        this.timeVisible = true
        this.forms = {
          eleName: this.dioDataitem.eleName ? this.dioDataitem.eleName : '',
          finallySosTime: this.dioDataitem.finallySosTime ? this.dioDataitem.finallySosTime : '',
          sureTime: item.time ? item.time : '',
          arrivalTime: this.dioDataitem.rescueTask.presentTime ? this.dioDataitem.rescueTask.presentTime : ""
        }
      },
      getType(type) {
        let obj = null
        this.stateList.forEach((qs) => {
          if (qs.value == type) {
            obj = qs.label
          }
        })
        return obj
      },
    }
  }
</script>

<style scoped>
  .timeType {
    display: flex;
    justify-content: space-between;
    height: 20px;
    align-items: center;
  }

  .timeTypes {
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    margin-top: -10px;
  }

  .mapBom {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 650px;
    height: 7vh;
  }

  #allmap {
    width: 100%;
    height: 30vh;
    color: #444;
  }

  .showEl {
    /* width: 400px; */
    /* overflow-y: scroll; */
    height: 58vh;
    margin-right: 20px;
  }

  /* span,
p,
h3 {
  color: white;
} */
</style>
