<template>
    <el-dialog
        custom-class="custom-dialog"
        title="电梯数据"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>电梯名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯注册代码:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="code"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯型号:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="marking"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯品牌:</span>
                    <el-select v-model="brand" filterable placeholder="请选择">
                        <el-option
                        v-for="item in brandOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="header-item">
                    <span>在线状态:</span>
                    <el-select v-if="adminInfo.userName != 'scjg'" v-model="lineStatus" filterable placeholder="请选择">
                        <el-option label="电梯总数" :value="-1" />
                        <el-option label="入网数" :value="0" />
                        <el-option label="在线" :value="1" />
                        <el-option label="离线" :value="2" />
                        <el-option v-if="adminInfo.userName == 'admin'" label="24小时在线" :value="10" />
                    </el-select>
                    <el-select v-else v-model="lineStatus" placeholder="请输入" clearable>
                        <el-option label="全部" :value="1" />
                        <!-- <el-option label="在线" :value="1" /> -->
                    </el-select>
                </div>
                <div class="header-item">
                    <span>故障状态:</span>
                    <el-select v-model="faultStatus" filterable placeholder="请选择">
                        <el-option
                        v-for="item in faultOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>

                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button  v-if="adminInfo.userName != 'scjg'" type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                height="500px"
                ref="multipleTable"
                stripe
                style="width: 100%">
                    <el-table-column type="index" label="序号" width="50px" />
                    <el-table-column align="center" prop="code" label="电梯注册代码" width="150px" />
                    <el-table-column align="center" prop="name" label="电梯名称" min-width="200px" />
                    <el-table-column align="center" prop="eleModel" label="电梯型号" width="150px" />
                    <el-table-column align="center" prop="brandName" label="电梯品牌" width="150px" />
                    <el-table-column align="center" prop="eleAddress" label="分布地区" width="150px" />
                    <el-table-column align="center" prop="status" label="在线状态" width="150px" >
                        <template slot-scope="scope">
                            {{getStatusText(scope.row.status)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="failureStatus" label="故障状态" width="150px" >
                        <template slot-scope="scope">
                            {{getTypesText(scope.row.failureStatus)}}
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
						<template slot-scope="scope">
							<el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
						</template>
					</el-table-column>
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

        <Action v-if="actionDialogVisible" :initData="initData" ref="acitonDtzl" />
        <MonitorInfo
            ref="acitonDtjk"
            v-if="monitorDialogVisible"
            :eleInfo="monitorData"
            :activeNameProps="'dtjk'"
            :elevatorId="monitorData.id"
            :deviceCode="monitorData.deviceCode"
            @closeMonitor="closeMonitor"
        />
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import Action from '../../dtsj/dtzl/components/action'
import MonitorInfo from '../../dtsj/dtjk/components/monitorInfo'
export default {
    components: {
        Pagination,
        Action,
        MonitorInfo
    },
    props:{
        initLineStatus:{
            default:0,
            type:Number|String
        },
        initFaultStatus:{
            default:0,
            type:Number|String
        },
    },
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            code:'',
            marking:'',
            brand:'',
            lineStatus:this.initLineStatus,
            faultStatus:this.initFaultStatus,
            brandLoading:true,
            brandOptions:[],
            lineOptions:[
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 1,
                    label: '在线'
                },
                {
                    value: 2,
                    label: '离线'
                },
                {
                    value: 10,
                    label: '在线'
                },
            ],
            faultOptions:[
                {
                    value:0,
                    label:'全部'
                },
                {
                    value:1,
                    label:'正常'
                },
                {
                    value:2,
                    label:'故障中'
                },
            ],
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,
            //电梯详情
            actionDialogVisible:false,
            initData: {},
            monitorDialogVisible:false,
            monitorData: {},

            adminInfo: JSON.parse(sessionStorage.getItem('adminInfo'))
        }
    },
    computed:{
        params(){
            return {
                current:this.current,
                size:this.size,
                name:this.name,
                code:this.code,
                eleModel:this.marking,
                brandId:this.brand,
                status:this.lineStatus,
                failureStatus:this.faultStatus,
            }
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog')
        },
        reset(){
            this.name=''
            this.code=''
            this.marking=''
            this.brand=''
            this.lineStatus=this.initLineStatus
            this.faultStatus=this.initFaultStatus
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },
        getTable(){
            this.loading=true
            this.$http.post('/api/ele/web/bigScreen/getBigDataEleList', this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                this.loading=false
                }
            })
        },
        getBrandList(){
            this.brandLoading=true
            this.$http.post('/api/ele/web/brand/list', {current:1,size:999999}).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.brandOptions=res.data.data.records
                }
                this.brandLoading=false
            })
        },
        getStatusText(status){
            const list = this.lineOptions.filter(item=>item.value == status)
            return list.length > 0 ? list[0].label : status
        },
        getTypesText(status){
            const list=this.faultOptions.filter(item=>item.value==status)
            return list.length>0?list[0].label:status
        },
        // 查看
        check(item) {
            console.log(item,'item');
            if (item.status == 1 || item.status == 10) {
                this.monitorDialogVisible = true
                this.monitorData = item
            } else {
                this.actionDialogVisible = true
                this.$http
                    .get(`/api/ele/web/elevatorInfo/detail?id=${item.id}`)
                    .then((res) => {
                        if (res.data.success) {
                            const data = res.data.data
                            data.deviceSosSwitch = res.data.data.deviceSosSwitch ?
                                JSON.parse(res.data.data.deviceSosSwitch) : []
                            data.sosSwitchStatus = res.data.data.sosSwitchStatus ?
                                JSON.parse(res.data.data.sosSwitchStatus) : []
                            data.terminalSosSwitch = res.data.data.terminalSosSwitch ?
                                JSON.parse(res.data.data.terminalSosSwitch) : []
                            data.areaCodeArr = res.data.data.areaCodes ?
                                JSON.parse(res.data.data.areaCodes) : []
                            this.initData = data
                            this.$nextTick(() => {
                                this.$refs.acitonDtzl.init('check', data, () => {
                                    this.getTable()
                                })
                            })
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },

        closeMonitor() {
            this.monitorDialogVisible = false
        },
    },
    created(){
      console.log(this.initLineStatus,this.initFaultStatus,666)
        this.getTable()
        this.getBrandList()
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh;
    // overflow-y: scroll;
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>
