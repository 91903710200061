<template>
  <div class="container" style="display: unset;">
    <div class="search-div">
      <span>请输入搜索的地址</span>
      <el-input
        id="tipInput"
        v-model="inputSearchVal"
        placeholder="请输入"
        clearable
        style="width: 40%; margin: 0 20px;"
      >
      </el-input>
      <el-button
        icon="el-icon-search"
        @click="searchKeyword"
      >
        搜索
      </el-button>
    </div>

    <div id="map-container"></div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    lnglat: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: null,
      markers: [],
      markersPosition: [],
      geoCoder: null,
      address: null, // 地址
      areaCode: null, // 地区码
      // addressName: null,
      inputSearchVal: '',
      centerInfo: null,
    }
  },
  mounted() {
    this.mapInit()
    this.mapSearchInit()
  },
  methods: {
    mapInit() {
      if (this.lnglat.length > 0) {
        this.map = new AMap.Map('map-container', {
          zoom: 16, // 级别
          center: this.lnglat,
          resizeEnable: true,
        })
        this.setMapMarker(this.lnglat)
      } else {
        this.map = new AMap.Map('map-container', {
          zoom: 16, // 级别
          // center: this.lnglat,
          resizeEnable: true,
        })
      }
      this.geoCoder = new AMap.Geocoder()
      this.handlerMapClick()
      this.markersPosition = this.lnglat
    },
    handlerMapClick() {
      this.map.on('click', (e) => {
        // 点击坐标
        console.log(e,'handlerMapClick');
        this.markersPosition = [e.lnglat.lng, e.lnglat.lat]
        this.centerInfo = e.lnglat
        this.removeMarker()
        // 设置新的标记
        this.setMapMarker()
        // eslint-disable-next-line no-undef
        this.getInfo()
      })
    },
    // 根据坐标获取位置信息
    getInfo() {
      this.geoCoder.getAddress(this.markersPosition, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          console.log(result,'result.regeocode');
          console.log('点击坐标信息：', this.markersPosition)
          // this.address = result.regeocode.formattedAddress
          this.$set(this, 'address', result.regeocode.formattedAddress)
          console.log('点击位置信息：', this.address)
          // this.areaCode = result.regeocode.addressComponent.towncode
          this.$set(
            this,
            'areaCode',
            `${result.regeocode.addressComponent.adcode}000000`
          )
          this.inputSearchVal = this.address
          console.log('areaCode', this.areaCode)
          this.$nextTick(() => {
            this.$emit('clickAddress', {
              markersPosition: this.markersPosition,
              address: this.address,
              areaCode: this.areaCode,
            })
          })
        }
      })
    },
    // 设置点击位置的标记
    setMapMarker(lnglat) {
      let marker = new AMap.Marker({
        map: this.map,
        position: lnglat ?? this.markersPosition,
      })
      // 将 markers 添加到地图
      this.markers.push(marker)
    },
    // 添加标记
    addMarker() {
      //   经度 纬度
      let lng = Math.random() * 135.05 + 73.3
      let lat = Math.random() * 53.33 + 3.51
      console.log('添加标记', [lng, lat])
      // 添加标记
      this.map.setFitView()

      let marker = new AMap.Marker({
        map: this.map,
        position: [lng, lat],
      })
      // 将 markers 添加到地图
      this.markers.push(marker)

      this.map.setFitView()

      // 鼠标点击marker弹出自定义的信息窗体
      // eslint-disable-next-line no-undef
      AMap.Event.addListener(marker, 'click', function (e) {
        console.log('点击marker', e)
      })
    },
    // 删除之前后的标记点
    removeMarker() {
      if (this.markers) {
        this.map.remove(this.markers)
      }
    },
    /** 初始化搜索工具 */
    mapSearchInit() {
      let that = this
      // 绑定自动提示
      AMap.plugin(['AMap.AutoComplete', 'AMap.PlaceSearch'], function () {
        var autoOptions = {
          input: 'tipInput',
        }
        var autoCompleteComponent = new AMap.AutoComplete(autoOptions)
        var placeSearch = new AMap.PlaceSearch({
            map: that.map
        }); 
        // 监听选中项
        AMap.Event.addListener(
          autoCompleteComponent,
          'select',
          function (data) {
            if (data.poi.adcode && data.poi.location != undefined) {
              //定位到中心点
              that.map.setCenter(data.poi.location)
              that.centerInfo = data.poi.location
            } else {
              placeSearch.setCity(data.poi.adcode);
              placeSearch.search(data.poi.name);  //关键字查询查询
              setTimeout(()=>{
								placeSearch.clear();
							},2000)
            }
          }
        )
        that.autoCompleteComponent = autoCompleteComponent
        // 注册placeSearch组件
        that.placeSearchComponent = new AMap.PlaceSearch({
          // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
          city: '北京',
        })
      })
    },
    /** 关键词搜索 */
    searchKeyword() {
      console.log(this.inputSearchVal, 123132132132);
      console.log(this.centerInfo,'centerInfo');
      let that = this
      that.placeSearchComponent.search(that.inputSearchVal, function (status, result) {
        if (status === 'complete' && result.info === 'OK') {
          console.log(result,'result');
          // 关键字联想的选项内容
          // that.poiList = result.poiList.pois
          if (result.poiList.pois.length > 0) {
            that.map.setCenter(result.poiList.pois[0].location)
            that.markersPosition = [result.poiList.pois[0].location.lng, result.poiList.pois[0].location.lat]
            that.centerInfo = result.poiList.pois[0].location
            that.removeMarker()
            // 设置新的标记
            that.setMapMarker()
            // eslint-disable-next-line no-undef
            that.getInfo()
          } else {
            that.$message({
              message: '没有查到结果',
              type: 'warning'
            })
          }
        } else {
          that.$message({
            message: '没有查到结果',
            type: 'warning'
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
#map-container {
  width: 100%;
  height: 50vh;
}
.search-div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/deep/.amap_lib_placeSearch_poi{
  opacity: 0;
}
</style>