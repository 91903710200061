//获取星期*
const getDay=(date=new Date(),arr=['星期日','星期一','星期二','星期三','星期四','星期五','星期六'])=>{
   return arr[date.getDay()]
}

//计算时间
const getTimeVal=(s)=>{
  if(!s){
    return {
      unit:'秒',
      value:0
    }
  }else if(s<60){
      return {
          unit:'秒',
          value:s
      }
  }else if(s>=60&&s<60*60){
      return {
          unit:'分钟',
          value:Math.floor(s/60)
      }
  }else if(s>=60*60&&s<24*60*60){
      return {
          unit:'小时',
          value:Math.floor(s/(60*60))
      }
  }else if(s>=24*60*60){
      return {
          unit:'天',
          value:Math.floor(s/(24*60*60))
      }
  }
}

/**
 * 返回2位小数点的浮点数
 * @param {Number|String} value 
 * 
 */
const toFixedTwo=(value)=>{
    return parseFloat(parseFloat(value).toFixed(2))
  }
  
  
  /**
   * 日期格式化
   * @param {date} oldDate new Date能接受的格式
   * @param {string} format 
   * @returns string
   */
  const formatDate = (oldDate, format = 'yyyy-MM-dd HH:mm:ss') => {
      if(!oldDate) return '-'
      const date = new Date(oldDate)
      const config = {
        yyyy: date.getFullYear(),
        M: date.getMonth() + 1,
        MM: formatNum(date.getMonth() + 1),
        W: date.getDay(),
        WW: formatNum(date.getDay()),
        d: date.getDate(),
        dd: formatNum(date.getDate()),
        H: date.getHours(),
        HH: formatNum(date.getHours()),
        h: date.getHours() > 12 ? (date.getHours() - 12) : date.getHours(),
        hh: formatNum(date.getHours()) > 12 ? (formatNum(date.getHours()) - 12) : formatNum(date.getHours()),
        m: date.getMinutes(),
        mm: formatNum(date.getMinutes()),
        s: date.getSeconds(),
        ss: formatNum(date.getSeconds()),
        A: date.getHours() < 12 ? 'AM' : 'PM',
        a: date.getHours() < 12 ? 'am' : 'pm'
      }
      const formatConfigs = format.match(/[a-zA-Z]+/g)
      formatConfigs.forEach(item => {
        format = format.replace(item, config[item])
      })
      return format
  }
  function formatNum(num) {
    return num < 10 ? `0${num}` : num
  }
  /**
   * 字节转换
   * @param {number|string} value 转换目标值
   * @param {number} targetType 转换目标类型 0=byte,1=kb,2=mb...
   * @param {number} sourceType 最终转换类型，同targetType
   * @param {Array} sourceName 转换类型映射名称数组
   */
  const byteConversion=(value,targetType=0,sourceType=2,sourceName=['Byte','KB','MB','G'])=>{
    value=parseInt(value)
    return (sourceType>targetType?(value/Math.pow(1024,sourceType-targetType)).toFixed(2):sourceType<targetType?(value*Math.pow(1024,sourceType-targetType)).toFixed(2):value)+sourceName[sourceType]
  }
  
  /**
   * 时间戳转换（服务器返回的时间戳和js的位数不同）
   * @param {date} oldDate 
   * @param {string} format 
   * @returns string
   * 
   */
  const serverFormatDate=(oldDate, format)=>{
    return formatDate(oldDate*1000, format)
  }
  
  /**
   * 秒转时分秒
   * @param {number} time 
   * @param {string} separator 
   * @returns string
   * 
   */
  const secondToHMS=(time,separator=':',add=false) => {
    // 转换为式分秒
    let h=0
    if (add){
      h = Math.floor(time/3600)
    }else{
      h = parseInt(time / 60 / 60 % 24)
    }
    h = h < 10 ? '0' + h : h
    let m = parseInt(time / 60 % 60)
     m = m < 10 ? '0' + m : m
    let s = parseInt(time % 60)
     s = s < 10 ? '0' + s : s
    return `${h}${separator}${m}${separator}${s}`
  }
  /**
   * 根据时间对象判断是否可选
   * @param {number} point 
   * @param {Date} time 
   * @param {Object} operation 
   * point 计算起点：天数，指在当日加值为起点
   * day：可选范围天数
   * next：向前向后计算
   * @returns boole
   */
  const disabledDate=(time,operation={point:0,day:31,next:true})=>{
    if (time.getTime() > new Date().getTime() + operation.point * 8.64e7  ) {
      const num=operation.next?operation.day * 8.64e7:0
      return time.getTime() >= new Date().getTime() + num + operation.point * 8.64e7;
    } else {
      const num=operation.next?0:operation.day * 8.64e7
      return time.getTime() < Date.now() - num + operation.point * 8.64e7; 
    }
  }
  
  
  export {
    getDay,
    getTimeVal,
    toFixedTwo,
    formatDate,
    byteConversion,
    serverFormatDate,
    secondToHMS,
    disabledDate
  }