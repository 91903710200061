<template>
	<div class="bin">
		<span class="TotleTit">{{information.tit}}</span>
		<div ref="Dpsie" class="Dpie"></div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import 'echarts-liquidfill'
	var value = 0.45;
	var value1 = 0.76;
	var data = [value, value1];
	export default {
		props: ['information'],
		data() {
			return {
				option: null,
				myChart: null,
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.getData()
				setTimeout(this.startTimer, 0);
			})
		},
		methods: {
			_dashed() {
				let dataArr = [];
				for (var i = 0; i < 100; i++) {
					if (i % 2 === 0) {
						dataArr.push({
							name: (i + 1).toString(),
							value: 20,
							itemStyle: {
								normal: {
									color: 'rgb(0,255,255,.3)',
								}
							}
						})
					} else {
						dataArr.push({
							name: (i + 1).toString(),
							value: 20,
							itemStyle: {
								normal: {
									color: 'rgb(0,0,0,0)',
									borderWidth: 1,
									borderColor: "rgba(0,255,255,1)"
								}
							}
						})
					}

				}
				return dataArr

			},
			// doing() {
			// 	let option = this.myChart.getOption();
			// 	option.series[1].startAngle = option.series[1].startAngle - 1;
			// 	this.myChart.setOption(option);
			// },
			// startTimer() {
			// 	let timer = setInterval(this.doing, 100);
			// },
			getData() {
				let that = this
				this.myChart = echarts.init(this.$refs.Dpsie);
				let value = this.information.value;
				let title = this.information.name;
				console.log(1144,value)
				// 传入数据生成 option
				this.option = {
					title: {
						text: '{a|' + value + '}\n{c|' + title + '}',
						x: 'center',
						y: 'center',
						textStyle: {
							rich: {
								a: {
									fontSize: 20,
									color: '#fff',
									fontWeight: '600',
								},
								c: {
									fontSize: 15,
									color: '#fff',
									padding: [5, 0]
								}
							}
						}
					},
					series: [{
							type: 'gauge',
							radius: '80%',
							clockwise: false,
							startAngle: '90',
							endAngle: '-269.9999',
							splitNumber: 30,
							detail: {
								offsetCenter: [0, -20],
								formatter: ' '
							},
							pointer: {
								show: false
							},
							axisLine: {
								show: true,
								lineStyle: {
									color: [
										[0, '#2CFAFC'],
										[36.7 / 100, '#0ff'],
										[1, '#0f232e']
									],
									width: 20
								}
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: true,
								length: 100,
								lineStyle: {
									shadowBlur: 10,
									shadowColor: 'rgba(0, 255, 255, 1)',
									shadowOffsetY: '0',
									color: '#020f18',
									width: 2
								}
							},
							axisLabel: {
								show: false
							}
						},
						{
							type: 'pie',
							radius: ['44%', '45%'],
							hoverAnimation: false,
							clockWise: false,
							itemStyle: {
								normal: {
									color: '#0C355E'
								}
							},
							label: {
								show: false
							},
							data: this._dashed()
						},

						{
							type: 'pie',
							radius: [0, '30%'],
							hoverAnimation: false,
							clockWise: false,
							itemStyle: {
								normal: {
									shadowBlur: 20,
									shadowColor: '#000',
									color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [{
										offset: 0,
										color: '#0FF',
									}, {
										offset: 1,
										color: '#060f20'
									}])
								}
							},
							label: {
								show: false
							},
							data: [100]
						},
						{
							type: 'pie',
							radius: ['64%', '65.5%'],
							hoverAnimation: false,
							clockWise: false,
							itemStyle: {
								normal: {
									shadowBlur: 20,
									shadowColor: 'rgba(0, 255, 255,.3)',
									color: '#0f232e'
								}
							},
							label: {
								show: false
							},
							data: [100]
						},
						{
							type: 'pie',
							radius: ['68%', '69.5%'],
							hoverAnimation: false,
							clockWise: false,
							itemStyle: {
								normal: {
									shadowBlur: 20,
									shadowColor: 'rgba(0, 255, 255,.3)',
									color: 'rgba(15, 35, 46,.6)',
								}
							},
							label: {
								show: false
							},
							data: [100]
						},
					]
				}
				this.myChart.setOption(this.option, true);
			},
		}
	};
</script>

<style>
	.TotleTit {
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 1vw;
	}

	.bin,
	.Dpie {
		width: 100%;
		height: 100%;
	}
</style>
