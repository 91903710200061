<template>
    <div class="container">
        <div @click="change(1)" :class="[val==1?'active-val':'','val']">周</div>
        <div class="line"></div>
        <div @click="change(2)" :class="[val==2?'active-val':'','val']">月</div>
        <div class="line"></div>
        <div @click="change(3)" :class="[val==3?'active-val':'','val']">年</div>
    </div>
</template>

<script>
export default {
    props:{
        initVal:{
            default:1,
            type:Number
        }
    },
    data() {
        return {
            val:this.initVal,
            timer:null
        }
    },
    methods:{
        change(val,automatic){
            if(!automatic){
                this.removeTimer()
            }
            this.val=val
            const time={
                start:0,
                end:0
            }
            if(val==1){
                let arr=[6,0,1,2,3,4,5]
                time.start=parseInt(((new Date(new Date().setHours(0, 0, 0) - arr[new Date().getDay()] *24 * 60 * 60 *1000)).getTime()) / 1000 ) *1000
                time.end=time.start+ 7 * 24 * 60 * 60 * 1000 - 1000
            }else if(val==2){
                time.start=(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0))).getTime()
                time.end=(new Date(new Date(new Date().getFullYear(), new Date().getMonth()+ 1, 0).setHours(23, 59, 59, 59))).getTime()
            }else if(val==3){
                time.start=(new Date(new Date().getFullYear(), 0, 1)).getTime()
                time.end=(new Date(new Date(new Date().getFullYear(), new Date().getMonth()+ 1, 0).setHours(23, 59, 59, 59))).getTime()
            }
            this.$emit('change',{time, dateVal:this.val})
            if(!automatic){
                this.initTimer()
            }
        },
        initTimer(){
            if(this.timer){
                this.removeTimer()
            }
            this.timer=setInterval(()=>{
                this.change(this.val==1?2:this.val==2?3:1,true)
            },10000)
        },
        removeTimer(){
            clearInterval(this.timer)
        }
    },
    mounted() {
        this.initTimer()
    },
    beforeDestroy() {
		this.removeTimer()
	},
}
</script>


<style>
.container{
    display: flex;
    align-items: center;
}
.val{
    cursor: pointer;
    line-height: 15px;
    color: #6C6C6C;
    font-size: 14px;
}
.line{
    height: 18px;
    width: 1px;
    margin: 0 6px;
    background-color: #2c3e50;
}
.active-val{
    color:#fff
}
</style>